import React, { useEffect, useMemo, useState } from 'react'
import { Typography } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import StepperSecureConnection from 'components/StepperSecureConnection'
import { PIN_STATUSES } from 'constants/highSecurityConnection'
import { SECURITY_PROCESS_TYPES } from 'constants/highSecurityConnection'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePinContext from 'hooks/usePinContext'
import usePinStatus from 'hooks/usePinStatus'
import useRolesViews from 'hooks/useRolesViews'
import { useHistory, Redirect } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { TenantNamesT, AdditionalStepsProps } from 'types'
import { existentPinSecureConnectionSteps, nonExistentPinSecureConnectionSteps } from '../constants'
import { SecureConnectionStepName } from '../types'
import useSetPin from './SetPin/hooks/useSetPin'
import useVerifyToken from './VerifyToken/hooks/useVerifyToken'
import { headerClasses } from './styles'

const SecureConnectionProcessPage: React.FC<RouteComponentProps<{ step?: SecureConnectionStepName }>> = ({ match }) => {
  const securityProcessType = SECURITY_PROCESS_TYPES.securitySetup
  const { step: urlStep } = match.params
  const history = useHistory()
  const { t } = useFavurTranslation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const taskUuid = queryParams.get('taskUuid') || queryParams.get('taskuuid')
  const { setActiveView } = useRolesViews()

  const [pinStatusData, statusP] = usePinStatus()
  const pinStatus = pinStatusData?.pinStatus ?? ''

  // Verify token step
  const [lowSecTenants, setLowSecTenants] = useState<TenantNamesT[] | undefined>(undefined)
  const [additionalStepsProperties, setAdditionalStepsProperties] = useState<AdditionalStepsProps>({})

  const { lowSecurityTenantsData, taskData, loading, onVerifyToken } = useVerifyToken(taskUuid)

  useEffect(() => {
    if (lowSecurityTenantsData) {
      setLowSecTenants(lowSecurityTenantsData)
    }
  }, [lowSecurityTenantsData, taskData])

  const matchedTenant = useMemo(() => {
    if (!lowSecTenants || !taskData?.person?.id) return undefined
    return lowSecTenants.find((tenant) => `${tenant.personId}` === `${taskData?.person?.id}`)
  }, [lowSecTenants, taskData])

  useEffect(() => {
    if (matchedTenant) {
      setLowSecTenants([matchedTenant])
    }
  }, [matchedTenant])

  // Set pin step
  const [firstPin, setFirstPin] = useState('')
  const [secondPin, setSecondPin] = useState('')
  const { pin } = usePinContext()

  const { setPinAndSecConnection } = useSetPin(securityProcessType, pin)

  const steps = useMemo(() => {
    if (pinStatus === PIN_STATUSES.notSet) {
      setAdditionalStepsProperties({
        TOKEN: {
          lowSecTenants: lowSecTenants,
          onVerifyToken: onVerifyToken,
          taskUuid: taskUuid,
          nextAction: 'pin',
        },
        SET_PIN: {
          taskUuid: taskUuid,
          onConfirmPin: setPinAndSecConnection,
          pin: pin,
          firstPin: firstPin,
          setFirstPin: setFirstPin,
          secondPin: secondPin,
          setSecondPin: setSecondPin,
        },
      })
      return nonExistentPinSecureConnectionSteps
    }
    if (pinStatus === PIN_STATUSES.set) {
      setAdditionalStepsProperties({
        TOKEN: {
          lowSecTenants: lowSecTenants,
          onVerifyToken: onVerifyToken,
          taskUuid: taskUuid,
          nextAction: 'success',
        },
      })
      return existentPinSecureConnectionSteps
    }

    return nonExistentPinSecureConnectionSteps
  }, [pinStatus, lowSecTenants, onVerifyToken, taskUuid, setPinAndSecConnection, pin, firstPin, secondPin])

  const header = useMemo(() => {
    return urlStep === 'set_pin' ? (
      <Typography variant="h3">{t('secureConnection.setPin.step.title')}</Typography>
    ) : (
      <BackwardLink onClickIcon={() => history.goBack()} title={t('secureConnection.verifyToken.step.title')} />
    )
  }, [history, t, urlStep])

  if (statusP === 'loading' || loading) {
    return (
      <Page hideNativeNavigation hideBackground columnCenter>
        <SimpleLoading />
      </Page>
    )
  }

  if ((statusP === 'error' || statusP === 'success') && !pinStatusData) {
    setActiveView(ROLE_VIEWS.frontliner)

    return <Redirect to={taskUuid !== null ? routes.secureConnectionWithTask(taskUuid) : routes.dashboard} />
  }

  return (
    <Page
      noPadding
      hideNativeNavigation
      hideHamburger
      hideBackground
      removeLastMargin
      header={header}
      sxHeader={headerClasses.subHeader}
    >
      <StepperSecureConnection
        urlStep={urlStep}
        steps={steps}
        additionalStepsProperties={additionalStepsProperties}
      ></StepperSecureConnection>
    </Page>
  )
}

export default SecureConnectionProcessPage
