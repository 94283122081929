import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BackwardLink from 'components/BackwardLink'
import useFilterUtils from 'components/Filter/useFilterUtils'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import { PIN_STATUSES } from 'constants/highSecurityConnection'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import usePinStatus from 'hooks/usePinStatus'
import useRolesViews from 'hooks/useRolesViews'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { tutorialNames, tutorialTypes } from '../../../constants/tutorials'
import useTutorials from '../../../hooks/useTutorials'
import { taskQueryApollo } from '../queries'
import { headerClasses } from '../styles'
import { SecureConnectionTaskT, TaskT } from '../types'
import Content from './Content'

const SecureConnectionDetails: React.FC<RouteComponentProps<{ favurUuid: string }>> = ({ match }) => {
  const {
    params: { favurUuid },
  } = match
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { activeView } = useRolesViews()
  const { setLoadFilterTasks } = useFilterUtils()
  const isOffice = useMemo(() => activeView === ROLE_VIEWS.office, [activeView])
  const [task, setTask] = useState<TaskT<SecureConnectionTaskT>>()
  const { beenShownBefore, isReady: tutorialsReady } = useTutorials()
  const [pinStatus, statusP] = usePinStatus()

  const { loading, refetch } = useJamesApolloQuery<{ task: TaskT<SecureConnectionTaskT> }>(taskQueryApollo, {
    fetchPolicy: 'no-cache',
    variables: { favurUuid, office: isOffice },
    onCompleted: (data) => {
      const { task: queriedTask } = data
      setTask(queriedTask)
    },
    onError() {
      history.push(routes.dashboard)
    },
  })

  const gotoBackLink = useCallback(() => {
    setLoadFilterTasks()
    history.push(routes.tasks)
  }, [history, setLoadFilterTasks])

  const goToTutorial = useCallback(() => {
    history.push(`${routes.tutorial}/${tutorialTypes.secureConnection}?taskUuid=${favurUuid}`)
  }, [favurUuid, history])

  useEffect(() => {
    if (
      !tutorialsReady ||
      Boolean(beenShownBefore(tutorialNames.SECURE_CONNECTION)) ||
      statusP === 'loading' ||
      pinStatus?.pinStatus === PIN_STATUSES.set
    ) {
      return
    }

    goToTutorial()
  }, [tutorialsReady, beenShownBefore, goToTutorial, statusP, pinStatus?.pinStatus])

  return (
    <Page
      hideNativeNavigation
      hideHamburger
      hideBackground
      removeLastMargin
      header={<BackwardLink onClickIcon={gotoBackLink} title={t('tasks.secureConnection.details.title')} />}
      sxHeader={headerClasses.subHeader}
    >
      {loading || !task ? (
        <SimpleLoading />
      ) : (
        <Content
          task={task}
          isOffice={isOffice}
          refetch={() => {
            refetch()
          }}
          goToTutorial={goToTutorial}
        />
      )}
    </Page>
  )
}

export default SecureConnectionDetails
