import { useState } from 'react'
import { PinStatusT } from 'pages/SecuritySetup/types'
import { pinStatusQuery } from 'shared/queries'
import { useJamesApolloQuery } from './useJamesApolloQuery'

export type PinQueryStatusT = 'loading' | 'success' | 'error'

const usePinStatus = (): [PinStatusT | undefined, PinQueryStatusT] => {
  const [status, setStatus] = useState<PinQueryStatusT>('loading')
  const { data: pinStatusData } = useJamesApolloQuery<{ pinStatus: PinStatusT }>(pinStatusQuery, {
    onCompleted() {
      setStatus('success')
    },
    onError() {
      setStatus('error')
    },
    fetchPolicy: 'no-cache',
  })
  return [pinStatusData?.pinStatus, status]
}

export default usePinStatus
